import _ from 'lodash';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { scrollTo } from '@/utils/dom';

@Component({
  head() {
    return {
      title: this.$t('faq.title').toString(),
    };
  },
  created(this: Faq) {
    if (this.$isServer) return;
    if (this.isLocateAreEn) return;

    window.location.replace('https://faq.moze.app')
  },
})
export default class Faq extends Vue {
  contents: Content[] | null = null;

  get isLocateAreEn() {
    return this.$i18n.locale === 'en'
  }

  get isAnchorInContent(): boolean {
    if (!_.isString(this.$route.hash)) return false;
    if (!_.isArray(this.contents)) return false;

    const hash = this.$route.hash.replace('#', '');
    const index = _.findIndex(this.contents, ['name', hash]);
    if (index < 0) return false;

    return true;
  }

  @Watch('isAnchorInContent')
  async handleCheckAnchorInContentChange(val: boolean) {
    if (val === true) {
      await this.$nextTick();
      const $content = this.$el.querySelector(this.$route.hash);
      if (!$content) return;

      scrollTo(
        $content as HTMLElement,
        window,
        68,
      );
    }
  }

  @Watch('$i18n.locale', { immediate: true })
  async handleLocaleChange(val: string) {
    if (_.isEmpty(val)) {
      return;
    }

    const isChinese = val === 'cn' || val === 'tw';

    const files = await Promise.all(_.compact([
      'moze_pro_vs_moze_3',
      'migration',
      'iap-transfer',
      'free-trial-can-auto-subscript',
      'process-cancel-subscript',
      'process-disable-account-or-project',
      'mortgage',
      'diff-export-backup',
      isChinese ? 'why-export-csv-is-garbled' : null,
      'can-import-other-app-data-to-moze',
      'can-not-send-email',
      'why-can-not-purches-theme-or-icons',
    ]).map((name) => (async () => ({
      name,
      content: await import(`@/assets/docs/faq/${val}/${name}.md`),
    }))()));

    this.contents = files.map((file) => ({
      name: file.name,
      content: file.content.default,
    }));
  }
}

interface Content {
  name: string;
  content: string;
}
