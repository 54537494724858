import { render, staticRenderFns } from "./Faq.vue?vue&type=template&id=4cf923d6&scoped=true&"
import script from "./faq.ts?vue&type=script&lang=ts&"
export * from "./faq.ts?vue&type=script&lang=ts&"
import style0 from "./faq.scss?vue&type=style&index=0&id=4cf923d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cf923d6",
  null
  
)

export default component.exports